<template>
  <div>
    <div class="warning-title">
      <SvgIcon icon-class="message-error" class="error-icon"></SvgIcon>
      <span class="warning-title-text">{{ warnTypeMap[currentSelected.warnType] }}</span>
    </div>
    <div class="warning-main">
      <div class="img-box" v-if="imgList.length > 0">
        <el-carousel indicator-position="none" :arrow="imgList.length > 1 ? 'hover' : 'never'" height="150px">
          <el-carousel-item v-for="(item, idx) in imgList" :key="idx">
            <!-- <el-image :src="item" fit="fill"></el-image> -->
            <img :src="item" class="img-item" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="info-box">
        <div class="info-item">机器人：{{ currentSelected.name }}</div>
        <div class="info-item" v-if="currentSelected.mapId">地图名称：{{ currentSelected.mapName }}</div>
        <div class="info-item" v-if="currentSelected.positionName">点位：{{ currentSelected.positionName }}</div>
        <div class="info-item">严重等级：{{ levelMap[currentSelected.level] }}</div>
        <!-- <div class="info-item">
          状态：{{ statusMap[currentSelected.status] }}
        </div> -->
        <div class="info-item">发生时间：{{ currentSelected.createTime }}</div>
      </div>
    </div>
    <div class="btn-group">
      <el-button size="small" @click="goView">查看</el-button>
      <el-button size="small" type="primary" @click="close">解除</el-button>
    </div>
  </div>
</template>

<script setup name="XJWarning">
import { computed } from 'vue'
import noPic from '@/assets/images/record_no_pic.png'
const emit = defineEmits()
const props = defineProps({
  warning: {
    type: Object,
    default: {}
  }
})
const currentSelected = computed(() => {
  return props.warning
})
const imgList = computed(() => {
  if (props.warning && props.warning.photoUrlList && props.warning.photoUrlList.length > 0) {
    return props.warning.photoUrlList
  } else {
    return []
  }
})
const warnTypeMap = {
  1: '人员闯入',
  2: '高温警报',
  3: '车辆违停',
  4: '人脸不符',
  5: '未戴口罩',
  6: '障碍物阻塞',
  7: '体温异常',
  8: '灯光警报',
  10: '人员聚集',
  11: '穿越围栏',
  12: '区域入侵',
  13: '禁行线',
  14: '物品遗留',
  15: '快速移动',
  16: '停车检测',
  17: '物品搬运',
  18: '人员徘徊',
  19: '非机动车违停',
  20: '人脸识别',
  21: '灭火器缺位',
  22: '火情、烟雾',
  23: '打架斗殴',
  24: '人员摔倒',
  25: '人员抽烟',
  26: '未穿戴安全着装',
  27: '人员睡岗',
  28: '人员离岗',
  50: '门开关检测',
  51: '人员非法闯入',
  52: '车辆非法闯入',
  53: '灭火器箱缺位',
  54: '人员非法闯入（行人检测）',
  60: '电动车未戴头盔',
  61: '闯红灯',
  62: '打架斗殴',
  63: '大货车'
}
const statusMap = {
  0: '未解除',
  1: '已解除'
}
const levelMap = {
  1: '轻微',
  2: '一般',
  3: '严重'
}
// const currentSelected = {
//   name: "巡检机器M22",
//   mapId: 1,
//   mapName: "安庆园区",
//   level: 1,
//   status: 1,
//   createTime: "2024-05-13 14:53:12",
// };
const close = () => {
  emit('cancel')
}
const goView = () => {
  emit('confirm')
}
</script>
<style scoped lang="scss">
.xj-warning-content {
  // width: 450px;
}

.warning-title {
  width: 100%;
  display: flex;
  align-items: center;

  .error-icon {
    font-size: 20px;
    margin-right: 10px;
  }

  .warning-title-text {
    font-size: 16px;
  }
}

.warning-main {
  margin: 20px 0;
  width: 100%;
  display: flex;

  .img-box {
    // width: 50%;
    width: 220px;

    .el-carousel__item {
      display: flex;
      justify-content: center;
    }

    .img-item {}
  }

  .info-box {
    width: 220px;
    margin-left: 10px;

    .info-item {
      margin-bottom: 9px;
    }
  }
}

.btn-group {
  display: flex;
  justify-content: center;
}
</style>
